import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Music from './pages/Music';
import logo from './assets/logo.webp';
import './App.css';

function App() {
  const NAVIGATION_LINKS = [
    {
      id: 1,
      title: 'Home',
      url: 'https://www.arvindprabhakar.com',
    },
    {
      id: 2,
      title: 'Spotify',
      url: 'https://open.spotify.com/artist/4t1PINwv7RTfJnKdqYrr6n?si=sXwVKCkfQx6kLxCfdr1ctw',
    },
    {
      id: 3,
      title: 'Apple Music',
      url: 'https://music.apple.com/us/artist/arvind-prabhakar/1569712865',
    },
    {
      id: 4,
      title: 'Amazon Music',
      url: 'https://music.amazon.com/artists/B0966NGKR1/arvind-prabhakar',
    },
    {
      id: 5,
      title: 'YouTube',
      url: 'https://www.youtube.com/@arvind_prabhakar',
    }
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="App">
      <AppBar id="navbar">
        <Toolbar id="navbar-menu">
          <IconButton id="hamburger" color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Button id="logo" color="inherit">
            <Box id="logo-icon" component="img" alt="Logo" src={logo} />
          </Button>
          <span id="navbar-links">
            <Button color="inherit">Home</Button>
            {NAVIGATION_LINKS.filter(
              (navigationLink) => navigationLink.id > 1
            ).map((navigationLink) => (
              <Button
                color="inherit"
                key={navigationLink.id}
                target="_blank"
                href={`${navigationLink.url}`}
              >
                {navigationLink.title.toUpperCase()}
              </Button>
            ))}
          </span>
        </Toolbar>
      </AppBar>
      <Drawer
        id="sidebar"
        anchor="left"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        <List id="sidebar-links">
          <ListItem disablePadding>
            <ListItemButton sx={{ paddingLeft: '32px' }} onClick={closeDrawer}>
              <ListItemText primary="HOME" />
            </ListItemButton>
          </ListItem>
          {NAVIGATION_LINKS.filter(
            (navigationLink) => navigationLink.id > 1
          ).map((navigationLink) => (
            <ListItem disablePadding key={navigationLink.id}>
              <ListItemButton
                sx={{ paddingLeft: '32px' }}
                target="_blank"
                href={`${navigationLink.url}`}
                onClick={closeDrawer}
              >
                <ListItemText
                  primary={`${navigationLink.title.toUpperCase()}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div id="page">
        <Music></Music>
      </div>
      <div id="footer">
        <p>Arvind Prabhakar &copy; {currentYear}. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default App;
