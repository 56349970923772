import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import jumbotron from '../assets/jumbotron-music.webp';
import falling from '../assets/falling.webp';
import cantResist from '../assets/cant-resist.webp';
import hyacinth from '../assets/hyacinth.webp';
import middleOfTheNight from '../assets/middle-of-the-night.webp';
import AlbumDetailsDialog from '../components/AlbumDetailsDialog';
import './Music.css';

function Music() {
  const ALBUMS = [
    {
      id: 1,
      title: 'Falling (feat. Madison Applegate)',
      art: falling,
      credits: 'Written by Arvind Prabhakar, & Madison Applegate',
      recordingInfo: 'Recorded, & Produced by Arvind Prabhakar at Mission 76 Studios',
      mixingInfo: 'Mixed by Arvind Prabhakar at Mission 76 Studios',
      masteringInfo: 'Mastered Using CloudBounce',
      releaseDate: 'Released May 28, 2021',
      label: '2021 Creative Fragments',
    },
    {
      id: 2,
      title: `Can't Resist (feat. Marlene Diaz)`,
      art: cantResist,
      credits: 'Written by Arvind Prabhakar, & Marlene Diaz',
      recordingInfo: 'Recorded, & Produced by Arvind Prabhakar at Mission 76 Studios',
      mixingInfo: 'Mixed by Max Rayden from Major Mixing',
      masteringInfo: 'Mastered by Max Rayden from Major Mixing',
      releaseDate: 'Released Mar 11, 2022',
      label: '2022 Creative Fragments',
    },
    {
      id: 3,
      title: 'Hyacinth',
      art: hyacinth,
      credits: 'Music by Arvind Prabhakar',
      recordingInfo: 'Produced by Arvind Prabhakar at Mission 76 Studios',
      mixingInfo: 'Mixed by Max Rayden from Major Mixing',
      masteringInfo: 'Mastered by Max Rayden from Major Mixing',
      releaseDate: 'Released Sep 23, 2022',
      label: '2022 Creative Fragments',
    },
    {
      id: 4,
      title: 'Middle of the Night',
      art: middleOfTheNight,
      credits: 'Music by Arvind Prabhakar',
      recordingInfo: 'Produced by Arvind Prabhakar at Mission 76 Studios',
      mixingInfo: 'Mixed by Arvind Prabhakar at Mission 76 Studios',
      masteringInfo: 'Mastered by Streaky at Streaky Studios',
      releaseDate: 'Released Aug 18, 2023',
      label: '2023 Creative Fragments',
    },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [albumDetails, setAlbumDetails] = useState(ALBUMS[1]);

  const scrollToAlbums = () => {
    const element = document.getElementById('albums-container');
    const yOffset = 56;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - yOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Grid container className="jumbotron">
        <Grid item xs={12}>
          <div className="button-container">
            <Button
              variant="outlined"
              size="large"
              color="inherit"
              onClick={scrollToAlbums}
            >
              Discography
            </Button>
          </div>
          <Card className="jumbotron-card">
            <CardMedia
              component="img"
              alt="Music Jumbotron"
              src={jumbotron}
              sx={{ minHeight: '424px' }}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container id="albums-container">
        {ALBUMS.reverse().map((album) => (
          <Grid item className="album" key={album.id} xs={12} sm={6}>
            <Card className="album-card">
              <CardActionArea
                onClick={() => {
                  setOpenDialog(true);
                  setAlbumDetails(album);
                }}
                onClose={() => setOpenDialog(false)}
              >
                <CardMedia
                  component="img"
                  alt={`${album.title}`}
                  src={album.art}
                />
                <CardContent sx={{ padding: '8px' }}>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{ color: '#1976d2' }}
                  >
                    LEARN MORE
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AlbumDetailsDialog
        albumDetails={albumDetails}
        openDialog={openDialog}
        closeDialog={closeDialog}
        setOpenDiaglog={setOpenDialog}
      ></AlbumDetailsDialog>
    </div>
  );
}

export default Music;
