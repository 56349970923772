import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

export default function AlbumDetailsDialog(props) {
  const { albumDetails, openDialog, closeDialog } = props;

  return (
    <Dialog open={openDialog} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{albumDetails.title}</DialogTitle>
      <DialogContent sx={{ paddingTop: '12px', paddingBottom: '0px'}}>
        <DialogContentText sx={{fontSize: '13px'}}>{albumDetails.credits}</DialogContentText>
      </DialogContent>
      <DialogContent sx={{ paddingTop: '12px', paddingBottom: '0px' }}>
        <DialogContentText sx={{fontSize: '13px'}}>{albumDetails.recordingInfo}</DialogContentText>
      </DialogContent>
      <DialogContent sx={{ paddingTop: '12px', paddingBottom: '0px' }}>
        <DialogContentText sx={{fontSize: '13px'}}>{albumDetails.mixingInfo}</DialogContentText>
      </DialogContent>
      <DialogContent sx={{ paddingTop: '12px', paddingBottom: '0px' }}>
        <DialogContentText sx={{fontSize: '13px'}}>{albumDetails.masteringInfo}</DialogContentText>
      </DialogContent>
      <DialogContent sx={{ paddingTop: '12px', paddingBottom: '0px' }}>
        <DialogContentText sx={{fontSize: '13px'}}>{albumDetails.releaseDate}</DialogContentText>
        <DialogContentText sx={{fontSize: '13px'}}>&copy; {albumDetails.label}</DialogContentText>
      </DialogContent>
      <DialogContent sx={{ paddingTop: '12px', textAlign: 'center'}}>
        <Button onClick={(closeDialog)}>Close</Button>
      </DialogContent>
    </Dialog>
  );
}
